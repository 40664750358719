import { isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, LOCALE_ID, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Pages, PagesService } from '../core/pages.service';
import { GalleryImage } from '../data/GalleryImage';
import Image from '../data/Image';
import { getFirestoreImageName } from '../shared/firestorage-image.directive';
import { SubscriberComponent } from '../shared/subscriber.component';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent extends SubscriberComponent implements OnInit, AfterViewInit {

  private static IMG_PARAM = 'img';

  images: GalleryImage[] | null;

  private dialogRef: MatDialogRef<ImageGalleryDialogComponent> | null;
  private imageOpenedFromGallery = false;
  private currentPath: string;

  settings: Settings | null = null;
  flickrWidth: number | null = null;
  flickrHeight: number | null = null;

  constructor(
    private pagesService: PagesService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private locationHistory: Location,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId: string,
    private zone: NgZone,
    public dialog: MatDialog) {
    super();
  }


  @HostListener('window:orientationchange', ['$event'])
  onRotate(event?) {
    setTimeout(() => this.onResize(), 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (isPlatformBrowser(this.platformId)) {
      // const currentFlickrWidth = this.flickrWidth;
      const screenWidth = window.innerWidth;
      this.zone.run(() => {
        let flickrWidth = 640;
        if (screenWidth < 650)
          flickrWidth = screenWidth - 10;
        else if (screenWidth > 1200)
          flickrWidth = 800;
        // if (flickrWidth != currentFlickrWidth) {
        this.setFlickrWidth(flickrWidth);
        // }
      });
    }
  }

  setFlickrWidth(flickrWidth: number) {
    setTimeout(() => {
      this.flickrWidth = flickrWidth;
      this.flickrHeight = flickrWidth * 0.67;
      // console.log(flickrWidth, this.flickrHeight);
      if (isPlatformBrowser(this.platformId))
        this.onFlickerWidthChanged();
    });
  }

  onFlickerWidthChanged() {

    const flickrFrame: HTMLIFrameElement = document.querySelector('.flickr-embed-frame');
    if (flickrFrame == null) {
      setTimeout(() => this.onFlickerWidthChanged(), 100);
      return;
    }
    if (flickrFrame != null) {

      flickrFrame.style.position = 'absolute';
      flickrFrame.style.top = '0';
      flickrFrame.style.left = '0';
      flickrFrame.style.width = '100%';
      flickrFrame.style.height = '100%';
      flickrFrame.style.border = 'none';

      flickrFrame.contentWindow.document.body.style.height = '100%';
      flickrFrame.contentWindow.document.body.parentElement.style.height = '100%';

      const flickrEmbed: HTMLElement = flickrFrame.contentWindow.document.querySelector('.flickr-embed');
      if (flickrEmbed != null) {
        flickrEmbed.style.width = '100%';
        flickrEmbed.style.height = '100%';
      }
      const flickrPhoto: HTMLElement = flickrFrame.contentWindow.document.querySelector('.flickr-embed-photo');
      if (flickrPhoto != null) {
        flickrPhoto.style.width = '100%';
        flickrPhoto.style.height = '100%';
        flickrPhoto.style.cursor = 'default';
        const anchors = flickrPhoto.getElementsByTagName('a');
        for (let a = 0; a < anchors.length; a++) {
          const anchor: HTMLElement = anchors.item(a);
          if (!anchor.classList.contains('slide'))
            continue;
          anchor.style['flex-grow'] = 1;
          anchor.style['pointer-events'] = 'none';
          const images = anchor.getElementsByTagName('img');
          for (let i = 0; i < images.length; i++) {
            const image: HTMLElement = images.item(i);
            image.style.width = '100%';
            image.style.height = '100%';
            image.style['object-fit'] = 'contain';
          }
        }

        const sharing: HTMLElement = flickrPhoto.querySelector('.sharing');
        // Can't hide like this, it's added for each photo
        // sharing.style.display = 'none';
      }
    }
  }

  correctFlickrFrameSize() {
    const flickrFrame: HTMLIFrameElement = document.querySelector('.flickr-embed-frame');
    if (flickrFrame != null) {
      flickrFrame.style.height = '100%';
    }
  }

  ngOnInit(): void {
    // this.setFlickrWidth(640);
    this.currentPath = this.locationHistory.path();

    this.pagesService.setPageMeta(Pages.gallery, this.locale);

    this.pagesService.getPage(Pages.gallery, this.locale)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(page => {
        this.zone.run(() => {
          this.settings = (page.settings as any);
        });
        this.onResize();
      });

    this.pagesService.getGalleryImages()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(images => {
        const NO_POS = 1000000;
        this.images = [...images].sort((i0: GalleryImage, i1: GalleryImage) =>
          (i0.pos || NO_POS) - (i1.pos || NO_POS));

        const openImage = this.activeRoute.snapshot.queryParamMap.get(ImageGalleryComponent.IMG_PARAM);
        if (openImage != null) {
          this.showImage(openImage);
        }
      });

    this.activeRoute.queryParamMap
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(map => this.showImage(map.get(ImageGalleryComponent.IMG_PARAM)));
  }

  ngAfterViewInit() {
    this.onResize();

    if (isPlatformBrowser(this.platformId)) {
      this.loadFlickr();
    }
  }

  loadFlickr() {
    if (isPlatformBrowser(this.platformId)) {

      const script = document.createElement('script');
      script.async = true;
      script.src = `//embedr.flickr.com/assets/client-code.js`;

      const fbRoot = document.getElementById('gallery-root');
      if (fbRoot != null) {
        fbRoot.appendChild(script);
      }
    }
  }

  getThumbImage(image: GalleryImage): Image {
    return this.pagesService.getGalleryImageThumb(image);
  }

  trackBy(index: number, object: GalleryImage): any {
    return object.getImage();
  }

  getImageLabel(image: GalleryImage): string | null {
    if (image.info != null) {
      const info = image.info[this.locale];
      if (info != null) {
        return info.name;
      }
    }
    return null;
  }

  setImageOpenedFromGallery() {
    this.imageOpenedFromGallery = true;
  }

  gotoImage(image: GalleryImage, replace = false) {
    const queryParams = this.getImageQueryParameters(image);
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: replace
    });
  }

  replaceImageParameter(image: GalleryImage) {
    this.gotoImage(image, true);
  }

  getImageQueryParameters(image: GalleryImage): any {
    const queryParams = {};
    queryParams[ImageGalleryComponent.IMG_PARAM] = getFirestoreImageName(image.getImage());
    return queryParams;
  }

  showImage(imageName: string | null) {
    this.closeDialog();
    this.clearParam();
    // this.currentPath = this.locationHistory.path();
    // if (imageName == null) {
    //   this.closeDialog();

    // } else if (this.images != null) {
    //   const image = this.images.find(img => imageName === getFirestoreImageName(img.getImage()));
    //   if (image != null) {
    //     this.openDialog(image);
    //   } else {
    //     this.closeDialog();
    //   }
    // }
  }

  openDialog(image: GalleryImage) {

    this.pagesService.setPageMeta(Pages.gallery, this.locale,
      { titleSuffix: getFirestoreImageName(image.getImage()) });

    if (this.dialogRef != null) {
      this.dialogRef.componentInstance.image = image;
      return;
    }

    this.dialogRef = this.dialog.open(ImageGalleryDialogComponent, {
      data: {
        image
      },
      autoFocus: false,
      width: '95vw',
      height: '95vh',
      maxWidth: '95vw',
      maxHeight: '95vh',
      backdropClass: 'image-dialog-backdrop',
      panelClass: 'image-dialog-panel',
    });

    this.dialogRef.keydownEvents().subscribe(event => {
      let newIndex = this.images.indexOf(this.dialogRef.componentInstance.image);
      if (event.key === 'ArrowLeft') {
        newIndex--;
      } else if (event.key === 'ArrowRight') {
        newIndex++;
      } else {
        return;
      }
      if (newIndex >= 0 && newIndex < this.images.length) {
        const newImage = this.images[newIndex];
        this.replaceImageParameter(newImage);
      }
    });

    this.dialogRef.afterClosed().subscribe((ref) => {
      this.pagesService.setPageMeta(Pages.gallery, this.locale);
      this.dialogRef = null;

      this.goBackIfCameFromGallery();
    });
  }

  goBackIfCameFromGallery() {
    if (this.locationHistory.path(false).startsWith(this.currentPath) && this.imageOpenedFromGallery) {
      this.locationHistory.back();

    } else {
      this.clearParam();
    }
  }

  clearParam() {
    const queryParams = {};
    queryParams[ImageGalleryComponent.IMG_PARAM] = null;
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

  closeDialog() {
    if (this.dialogRef != null) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

}

class Settings {
  flickr_album_href = '';
  flickr_image_src = '';
}

@Component({
  selector: 'app-image-gallery-dialog',
  template: `
  <button mat-dialog-title mat-icon-button mat-dialog-close class="close">
        <i class="material-icons md-button md-light">close</i>
  </button>
  <mat-dialog-content class="mat-typography dialogContent">
      <img [appFirestoreImage]="image.getImage()" />
  </mat-dialog-content>
  `,
  styles: [
    `:host, .dialogContent {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }`,
    `.close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0.2em;
      border-radius: 4px;
    }`,
    `.dialogContent {
      max-height: 100%;
      padding: 0;
    }`,
    `img {
      max-width: 100%;
      max-height: 100%;
    }`
  ]
})
export class ImageGalleryDialogComponent {
  image: GalleryImage;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { image: GalleryImage }) {
    this.image = data.image;
  }
}