import { Component, Inject, LOCALE_ID, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { BookingService } from '../core/booking.service';
import ServiceInfo, { SeasonPrice } from '../data/ServiceInfo';
import { SubscriberComponent } from '../shared/subscriber.component';
import { convertDateToUTC, getCurrentDate } from '../util/dates';
import { formatTranslation } from '../util/helpers';
import { getServicePrice } from '../common/functions';
import BookingRequest from '../data/BookingRequest';
import CalendarEvent from '../data/CalendarEvent';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackMessageComponent, SnackMessageProps } from '../shared/snack-message.component';
import { Pages, PagesService } from '../core/pages.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent extends SubscriberComponent implements OnInit {

  public services: ServiceInfo[] | null = null;
  public selectedService: ServiceInfo | null = null;

  public events: CalendarEvent[] | null = null;

  public defaultEventHours = 19;

  form = this.formBuilder.group({
    dateTime: [null, Validators.required],
    location: ['', Validators.required],
    message: [''],
    customer: this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required]
    })
  });

  @ViewChildren('serviceCard', { read: MatRipple }) cardRipples: QueryList<MatRipple>;

  public createdRequest: BookingRequest | null = null;

  constructor(
    private translocoService: TranslocoService,
    private formBuilder: UntypedFormBuilder,
    private bookingService: BookingService,
    private pageService: PagesService,
    private auth: AngularFireAuth,
    private snackBar: MatSnackBar,
    @Inject(LOCALE_ID) public locale: string) {
    super();
    this.auth.user.subscribe((user) => {
      if (user != null) {
        const customerGroup = this.form.controls.customer as UntypedFormGroup;
        customerGroup.setValue({
          name: user.displayName,
          email: user.email,
          phone: user.phoneNumber,
        });
      }
    });
  }

  ngOnInit(): void {
    this.pageService.setPageMeta(Pages.booking, this.locale);
    this.bookingService.getServices()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        services => this.services = services
      );
  }

  trackBy(index: number, object: ServiceInfo): any {
    return object.id;
  }

  getSeasonPrices(service: ServiceInfo): SeasonPrice[] {
    const prices = [...service.seasonPrices];
    prices.sort((a, b) => (a.from.month*31 + a.from.day) - (b.from.month*31 + b.from.day));
    return prices;
  }

  getServicePrice_(service: ServiceInfo): string {
    const price = getServicePrice(service, this.form.controls.dateTime.value);
    if (price > 0) {
      return `${price}${service.currency}`;
    }
    return this.translocoService.getTranslation('booking').negotiated_price;
  }

  selectService(service: ServiceInfo) {
    this.selectedService = service;
    this.cardRipples.toArray()[this.services.indexOf(service)].launch({
      centered: true
    });
    // Delay to reduce number of calendar requests
    this.bookingService.getEvents().then(events => this.events = events);
  }

  selectDate(date: Date) {
    this.form.controls.dateTime.setValue(date);
  }

  getSuccessMessage(text: string): string[] {
    const replacements = {};
    if (this.createdRequest != null) {
      replacements['{email}'] = this.createdRequest.customer.email;
    }
    return formatTranslation(text, replacements);
  }

  getErrors(control: AbstractControl, errors?: ValidationErrors[]): ValidationErrors[] | null {
    if (errors == null) {
      errors = [];
    }
    if (control instanceof UntypedFormGroup) {
      for (const c of Object.values(control.controls)) {
        const cErrors = this.getErrors(c, errors);
        if (cErrors != null) {
          errors = cErrors;
        }
      }
    } else {
      errors.push(control.errors);
    }
    errors = errors.filter(e => e);
    return errors.length > 0 ? errors : null;
  }

  onSubmit() {
    if (this.form.valid) {
      const request = new BookingRequest(this.translocoService.getActiveLang());
      Object.assign(request, this.form.value);
      request.service = this.selectedService;

      // Default start time for calendar events
      request.dateTime = new Date(request.dateTime);
      request.dateTime.setHours(this.defaultEventHours);
      request.dateTime.setMinutes(0);

      this.form.disable();
      this.bookingService.createBookingRequest(request)
        .then(
          ref => {
            this.createdRequest = request;
            // TODO set Id as query parameter and display the request (but how to secure?)
            // console.log(ref.id);
          }
        ).catch(error => {
          const message = formatTranslation(this.translocoService.getTranslation('booking').failure_message);
          // message.push(error.toString());
          this.snackBar.openFromComponent(SnackMessageComponent, {
            data: {
              message,
              error: true
            } as SnackMessageProps,
            panelClass: 'error-message'
          });
          this.form.enable();
        });
    }
  }
}
