import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDividerModule } from '@angular/material/divider';
import { BookingComponent } from './booking.component';
import { SharedModule } from '../shared/shared.module';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CalendarModule } from '../calendar/calendar.module';

@NgModule({
    declarations: [BookingComponent],
    imports: [
        SharedModule,
        MatButtonModule,
        MatInputModule,
        TextFieldModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatDividerModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        CalendarModule
    ]
})
export class BookingModule { }
