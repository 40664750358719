/**
 * Do gather application-wide, single use components in the CoreModule.
 *
 * Import it once (in the AppModule) when the app starts and never import it anywhere else.
 *
 * Do import all modules required by the assets in the CoreModule (e.g. CommonModule and FormsModule).
 */
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { CloudFunctionsService } from './cloud-functions.service';
import { AppErrorHandler } from './app-error-handler';
import { BookingService } from './booking.service';
import { PagesService } from './pages.service';
import { UserService } from './user.service';
import { AdminService } from './admin.service';

@NgModule({
  declarations: [],
  imports: [SharedModule],
  providers: [
    CloudFunctionsService,
    UserService,
    PagesService,
    BookingService,
    AdminService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
  bootstrap: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
