import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import CalendarEvent from '../data/CalendarEvent';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input()
  events: CalendarEvent[] | null;

  @Input()
  private defaultEventHours = 19;

  @Output()
  selectedDate = new EventEmitter<Date>();
  currentSelectedDate: Date | null = null;

  public today = new Date();

  public monthYear: Date;
  public monthYearOptions: Date[];

  public weekDays: Date[];

  public dateRows: Date[][] = [];

  constructor() { }

  ngOnInit(): void {

    this.monthYear = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
    this.configureMonthDays();

    this.monthYearOptions = [];
    let m = -12;
    while (m <= (12 + 3)) {
      if (m === 0) {
        this.monthYearOptions.push(this.monthYear);
      } else {
        const my = new Date(this.monthYear);
        my.setMonth(my.getMonth() + m, 1);
        this.monthYearOptions.push(my);
      }
      m++;
    }

    const monday = new Date();
    monday.setDate(monday.getDate() - monday.getDay() + 1);
    this.weekDays = [monday];
    let d = 1;
    while (d < 7) {
      const day = new Date(monday);
      day.setDate(day.getDate() + d);
      this.weekDays.push(day);
      d++;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
  }

  prevNextMonth(next: boolean) {
    let index = this.monthYearOptions.indexOf(this.monthYear);
    index += next ? 1 : -1;
    if (index >= 0 && index < this.monthYearOptions.length) {
      this.setMonthYear(this.monthYearOptions[index]);
    }
  }

  configureMonthDays() {

    const firstMonday = new Date(this.monthYear);
    firstMonday.setDate(firstMonday.getDate() - firstMonday.getDay() + 1);

    this.dateRows = [];
    let w = 0;
    let isNextMonth = false;
    while (!isNextMonth) {
      const weekRow: Date[] = [];
      this.dateRows.push(weekRow);
      let d = 0;
      while (d < 7) {
        const day = new Date(firstMonday);
        day.setDate(day.getDate() + w * 7 + d);
        day.setHours(this.defaultEventHours);
        // One minute into events' start time so we can match with calendar events
        day.setMinutes(1);

        isNextMonth = (day.getFullYear() > this.monthYear.getFullYear() || day.getMonth() > this.monthYear.getMonth());
        if (isNextMonth && w !== 0 && d === 0) {
          break;
        }

        weekRow.push(day);
        d++;
      }
      w++;
    }

  }

  isDateConfirmed(date: Date): boolean {
    if (this.events != null) {
      return this.events.find(event =>
        event.status === 'confirmed' &&
        event.start < date && event.end > date) != null;
    }
    return false;
  }

  isDateRequested(date: Date): boolean {
    if (this.events != null) {
      return this.events.find(event =>
        event.status === 'pending' &&
        event.start < date && event.end > date) != null;
    }
    return false;
  }

  setMonthYear(my: Date) {
    this.monthYear = my;
    this.configureMonthDays();
  }

  onDateSelect(date: Date) {
    if (date > this.today && !this.isDateRequested(date) && !this.isDateConfirmed(date)) {
      this.currentSelectedDate = date;
      this.selectedDate.emit(date);
    }
  }

}