import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}
  handleError(error) {
    if (isPlatformBrowser(this.platformId)) {
      console.log(error);
      alert(error);
    } else {
      throw error;
    }
  }
}
