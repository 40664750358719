import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import {
  Injectable,
  NgModule,
  InjectionToken,
  Inject,
  ErrorHandler,
} from '@angular/core';
import { environment } from '../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

// @Injectable({ providedIn: 'root' })
// export class TranslocoHttpLoader implements TranslocoLoader {
//   constructor(private http: HttpClient) { }
//   getTranslation(lang: string) {
//     return this.http.get<Translation>(`${environment.baseUrl}/assets/i18n/${lang}.json`);
//   }
// }

@Injectable({ providedIn: 'root' })
export class TranslocoFirebaseLoader implements TranslocoLoader {
  constructor(
    private firestore: AngularFirestore,
    @Inject(TRANSLATION_MODULES) private modules: string[],
    private errorHandler: ErrorHandler
  ) {}
  getTranslation(lang: string): Observable<Translation> {
    const pages = this.firestore.collection<Translation>('pages');
    return pages.valueChanges({ idField: 'pageName' }).pipe(
      map((pageDocuments: any[]) => {
        const firebaseTrans: Translation = {};
        pageDocuments.forEach(
          (page) => (firebaseTrans[page.pageName] = page[lang])
        );
        return firebaseTrans;
      }),
      catchError((error) => {
        this.errorHandler.handleError(error);
        return of(error);
      })
    );
  }
}
export const TRANSLATION_MODULES = new InjectionToken<string[]>(
  'Translation scopes',
  { factory: () => [] }
);

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'et'],
        defaultLang: 'et',
        fallbackLang: 'et',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoFirebaseLoader },
  ],
})
export class TranslocoRootModule {}
