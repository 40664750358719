import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { objectizeDocument } from '../data/FbDocument';
import Booking, { BookingStatus } from '../data/Booking';

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  private bookings: Observable<Booking[]>;
  private unhandledRequests: Observable<string[]>;

  constructor(
    private firestore: AngularFirestore
  ) { }

  getBookings(): Observable<Booking[]> {
    if (this.bookings == null) {
      this.bookings = this.firestore.collection<Booking>('bookings').valueChanges({ idField: 'id' })
        .pipe(map(
          coll => coll.map(booking => objectizeDocument(booking.id, booking, Booking))
        ));
    }
    return this.bookings;
  }

  getUnhandledRequests(): Observable<string[]> {
    if (this.unhandledRequests == null) {
      this.unhandledRequests = this.firestore.collection('customer_requests',
        ref => ref.where('handled', '==', false))
        .valueChanges({ idField: 'id' })
        .pipe(map(coll => coll.map(req => req.id)));
    }
    return this.unhandledRequests;
  }

  setBookingStatus(booking: Booking, newStatus: BookingStatus): Promise<void> {
    // TODO revise
    return this.firestore.collection<Booking>('bookings').doc(booking.id)
      .update({ status: newStatus });
  }
}
