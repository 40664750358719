import { Injectable } from '@angular/core';
import { httpsCallable } from 'firebase/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import BookingRequest from '../data/BookingRequest';
import { convertDateToLocal, convertDateToUTC } from '../util/dates';
import { objectizeDocument } from '../data/FbDocument';
import ServiceInfo from '../data/ServiceInfo';
import { CloudFunctionsService } from './cloud-functions.service';
import Event from '../data/CalendarEvent';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private uid: string | null = null;

  private services: Observable<ServiceInfo[]>;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private fs: CloudFunctionsService
  ) {
    this.auth.user.subscribe((user) => {
      if (user != null) {
        this.uid = user.uid;
      } else {
        this.uid = null;
      }
    });
  }

  getServices(): Observable<ServiceInfo[]> {
    if (this.services == null) {
      this.services = this.firestore.collection<ServiceInfo>('services').valueChanges({ idField: 'id' })
        .pipe(map(
          coll => coll.map(service => objectizeDocument(service.id, service, ServiceInfo))
        ));
    }
    return this.services;
  }

  getEvents(): Promise<Event[]> {
    const fbCallable = httpsCallable(this.fs.functions, 'getAnonymousEvents');
    return fbCallable(null)
      .then(data => ((data.data as any).events as Event[]))
      .then(events =>
        events.map(event => {
          event.start = convertDateToUTC(event.start.toString());
          event.end = convertDateToUTC(event.end.toString());
          return event;
        })
      );
  }

  createBookingRequest(request: BookingRequest)/* : Promise<DocumentReference> */ {
    request = request.getFbObject();
    request.customer.uid = this.uid;
    request.dateTime = convertDateToLocal(request.dateTime.toISOString());
    delete request.service.info;
    return this.firestore.collection('customer_requests').add(request);
  }
}
