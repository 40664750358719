import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'src/app/core/admin.service';
import { BookingService } from 'src/app/core/booking.service';
import Booking, { BookingStatus } from 'src/app/data/Booking';
import ServiceInfo from 'src/app/data/ServiceInfo';
import { SubscriberComponent } from 'src/app/shared/subscriber.component';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent extends SubscriberComponent implements OnInit {

  @Input()
  booking: Booking | null;

  bookingStatuses = Object.values(BookingStatus);

  public services: ServiceInfo[] | null = null;

  constructor(
    private bookingService: BookingService,
    private adminService: AdminService,
    @Inject(LOCALE_ID) public locale: string) {
    super();
  }

  ngOnInit(): void {

    this.bookingService.getServices()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        services => this.services = services
      );
  }

  getServiceName(booking: Booking): string {
    if (this.services != null) {
      const service = this.services.find(s => (booking.service || booking.request.service).name === s.name);
      if (service != null) {
        return service.info[this.locale].name;
      }
    }
    return 'Unknown service';
  }

  setStatus(newStatus: BookingStatus) {
    this.adminService.setBookingStatus(this.booking, newStatus);
  }
}
