import { Component, Inject, LOCALE_ID, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Pages, PagesService } from '../core/pages.service';
import { FaqStructure, getFaqStructure, QA } from '../data/Faq';
import { VideoInfo } from '../data/Video';
import { SubscriberComponent } from '../shared/subscriber.component';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent extends SubscriberComponent implements OnInit {

  jsonLdSchema = null;

  /*
  
      'uploadDate': '2016-03-31T08:00:00+08:00',
      'duration': 'PT1M54S',
      'contentUrl': 'https://www.example.com/video/123/file.mp4',
      'embedUrl': 'https://www.example.com/embed/123',
      'interactionStatistic': {
        '@type': 'InteractionCounter',
        'interactionType': { '@type': 'WatchAction' },
        'userInteractionCount': 5647018
      },

  */

  videos: VideoInfo[] | null;
  safeUrls: Map<string, SafeResourceUrl> = new Map();

  constructor(
    private pagesService: PagesService,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string) {
    super();
  }

  ngOnInit(): void {
    this.pagesService.setPageMeta(Pages.video, this.locale);
    this.pagesService.getVideos()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        videos => {
          this.zone.run(() => {
            this.videos = videos.filter(v => v.visible);
            this.videos.forEach(v => this.safeUrls.set(v.contentUrl, this.sanitizer.bypassSecurityTrustResourceUrl(v.contentUrl)));

            if (this.videos.length > 0) {
              const v = this.videos[0];
              this.jsonLdSchema = {
                '@context': 'https://schema.org',
                '@type': 'VideoObject',
                'name': v.name,
                'description': v.description,
                'thumbnailUrl': v.thumbnailUrl,
                'uploadDate': v.uploadDate,
                'contentUrl': v.contentUrl
              };
            }

            this.changeDetector.markForCheck();
          });
        }
      );
  }

  trackBy(index: number, object: VideoInfo): any {
    return object.contentUrl;
  }

}
