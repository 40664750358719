import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { FaqComponent } from './faq.component';
import { SharedModule } from '../shared/shared.module';
import { ImageGalleryComponent, ImageGalleryDialogComponent } from './image-gallery.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { VideoComponent } from './video.component';

@NgModule({
  declarations: [MainComponent, FaqComponent, VideoComponent, ImageGalleryComponent, ImageGalleryDialogComponent, PrivacyPolicyComponent],
  imports: [
    SharedModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  exports: [
    MainComponent,
    FaqComponent,
    VideoComponent
  ]
})
export class PagesModule { }
