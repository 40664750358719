import { Component, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-snack-message',
  template: `
    <div class="container" *transloco="let t; read: 'common'">
      <button mat-icon-button class="close" (click)="close()" [attr.aria-label]="t('close_message')">
        <i class="material-icons md-24">close</i>
      </button>
      <p *ngFor="let m of data.message; let i = index">
        <i *ngIf="i == 0 && data.error" class="error material-icons md-48">error_outline</i>
        {{m}}
      </p>
    </div>`,
  styles: [
    `.container {
        position: relative;
        padding: 1em;
      }`,
    `p {
        display: flex;
        align-items: center;
        margin-block-start: 0;
        margin-block-end: 0.5em;
      }`,
    `.close {
        position: absolute;
        right: 0;
        margin-right: -0.8em;
        margin-top: -1.5em;
      }`,
    `i.error {
        margin: 0.2em;
      }`
  ]
})
export class SnackMessageComponent {

  @HostBinding('class.error-message') isError = false;

  constructor(private snackBar: MatSnackBarRef<SnackMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackMessageProps) {
    this.isError = data.error;
  }

  close() {
    this.snackBar.dismiss();
  }

}

export class SnackMessageProps {
  message: string[] | string;
  error = false;
}