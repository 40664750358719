<div
  appScrollTo
  class="container"
  id="gallery-root"
  [style]="'width:' + (flickrWidth || '640') + 'px;height:' + (flickrHeight || '428') + 'px'"
>
  <!--
  <div class="columns">
    <div *ngFor="let c of [0, 1, 2]" class="column">
      <ng-container *ngFor="let image of images; index as idx">
        <div *ngIf="idx % 3 === c" class="image">
          <img
            #img
            [appFirestoreImage]="image.getImage()"
            (click)="openDialog(image)"
          />
          <div
            *ngIf="false && getImageLabel(image)"
            class="imageLabel gallery-image-label"
          >
            {{ getImageLabel(image) }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
-->

  <!-- <div class="row">
    <ng-container *ngFor="let image of images; trackBy: trackBy">
      <div class="image">
        <a
          [routerLink]="[]"
          [queryParams]="getImageQueryParameters(image)"
          queryParamsHandling="merge"
          (click)="setImageOpenedFromGallery()"
        >
          <img loading="lazy" [appFirestoreImage]="getThumbImage(image)" />
        </a>
      </div>
    </ng-container>
  </div> -->

  <div class="aspectRatio">
    <a
      *ngIf="settings != null"
      data-flickr-embed="true"
      data-header="false"
      data-footer="false"
      data-context="true"
      [href]="settings.flickr_album_href"
      ><img
        [src]="settings.flickr_image_src"
        [width]="flickrWidth || 640"
        [height]="flickrHeight || 428"
    /></a>
  </div>

  <!-- <iframe src="https://albumizr.com/a/qkOA" scrolling="no" frameborder="0" allowfullscreen width="700" height="400"></iframe> -->
</div>
