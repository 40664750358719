<ng-container *transloco="let t; read: 'main'">
  <section appScrollTo class="intro intro-underlay mat-elevation-z10">
    <div class="introInfo accent-contrast mat-elevation-z10">
      <app-formatted-text
        textAlign="center"
        [text]="t('intro.pitch')"
      ></app-formatted-text>
    </div>
    <div class="introImage">
      <img [appFirestoreImage]="introImgSrc" />
    </div>
    <div class="actions introActions">
      <button mat-raised-button color="primary" routerLink="/tellimine">
        {{ t("intro.booking_action_label") }}
      </button>
    </div>
    <div class="introFill"></div>
  </section>

  <div class="page-info-panel floating-header mat-elevation-z5">
    <h2>{{ t("about_title") }}</h2>
    <div
      *ngIf="t('about_text') != ' '"
      class="mat-elevation-z2 accent-contrast"
    >
      <app-formatted-text [text]="t('about_text')"></app-formatted-text>
    </div>
    <div class="video">
      <img
        [src]="videoThumbnail"
        routerLink="/video"
        width="480"
        height="360"
        alt="Video thumbnail"
        aria-label="Video thumbnail"
      />
      <div class="actions">
        <button mat-raised-button color="primary" routerLink="/video">
          {{ t("video_link_label") }}
        </button>
      </div>
    </div>
  </div>

  <div class="page-info-panel floating-header mat-elevation-z5">
    <h2>{{ t("members_title") }}</h2>
    <div
      *ngIf="t('members_text') != ' '"
      class="mat-elevation-z2 accent-contrast"
    >
      <app-formatted-text [text]="t('members_text')"></app-formatted-text>
    </div>
  </div>
  <section *ngIf="members != null" class="members">
    <ng-container *ngIf="members">
      <ng-container
        *ngFor="
          let row of [
            [members[0], members[1], members[2]],
            [members[3], members[5]],
            [members[4]]
          ];
          index as r;
          trackBy: trackBy
        "
      >
        <div
          [ngClass]="{
            'memberRow-1': r == 0,
            'memberRow-2': r == 1,
            'memberRow-3': r == 2
          }"
        >
          <ng-container
            *ngFor="let member of row; index as m; trackBy: trackBy"
          >
            <div
              [ngClass]="{
                'member mat-elevation-z10': true,
                'member-1': m == 0,
                'member-2': m == 1,
                'member-3': m == 2
              }"
            >
              <img loading="lazy" [appFirestoreImage]="member.image" />
              <div class="memberLabel accent-contrast">
                <div class="memberName">{{ member.name }}</div>
                <div>{{ member.description }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="memberRow-all">
        <ng-container
          *ngFor="
            let member of [
              members[4],
              members[5],
              members[3],
              members[0],
              members[1],
              members[2]
            ];
            trackBy: trackBy
          "
        >
          <div class="member mat-elevation-z10">
            <img loading="lazy" [appFirestoreImage]="member.image" />
            <div class="memberLabel accent-contrast">
              <div class="memberName">{{ member.name }}</div>
              <div>{{ member.description }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </section>

  <div class="page-info-panel floating-header mat-elevation-z5">
    <h2 *ngIf="t('playlist_title') != ' '">{{ t("playlist_title") }}</h2>
    <div
      *ngIf="t('playlist_text') != ' '"
      class="mat-elevation-z2 accent-contrast"
    >
      <app-formatted-text [text]="t('playlist_text')"></app-formatted-text>
    </div>
  </div>
  <section class="audio">
    <button
      *ngIf="!soundCloudWidgetContent"
      id="app-main-show-playlist"
      mat-raised-button
      color="primary"
      class="show-audio call-to-action"
      (click)="showSoundCloudWidgetContent()"
    >
      {{ t("playlist_show") }}
    </button>
    <mat-progress-spinner
      *ngIf="soundCloudWidgetContent && !soundCloudWidgetLoaded"
      color="primary"
      class="load-audio"
      mode="indeterminate"
      diameter="100"
    >
    </mat-progress-spinner>
    <iframe
      *ngIf="soundCloudWidgetEnabled"
      id="app-main-playlist-frame"
      title="Soundcloud player"
      frameborder="no"
      [src]="
        soundCloudWidgetContent ? soundcloudWidgetUrl || blankUrl : blankUrl
      "
      (load)="audioLoaded($event)"
    >
    </iframe>
  </section>
</ng-container>

<div #hiddenPrimary class="mat-button mat-primary hidden"></div>
