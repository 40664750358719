
function logWarning(message?: any, ...optionalParams: any[]) {
    // XXX dev mode in functions?
    // if (process.env.NODE_ENV === 'development') {
    console.warn('WARNING:', message, optionalParams);
    // }
}

/**
 * Client and cloud functions are using different firebase implementations (?),
 * thus we can't directly import firebase here
 */
export interface FirebaseAccess {
    serverTimestamp(): any;
}

export abstract class FbDocument {
    public static firebaseAccess: FirebaseAccess;

    id: string | null = null;
    /**
     * Because Firebase does not support "custom" objects.
     */
    getFbObject(setCreated = true): any {
        const fbObj: any = {};
        Object.assign(fbObj, this);
        this.toFbObject(fbObj);

        delete fbObj.id;
        if (setCreated && fbObj.created == null) {
            fbObj.created = FbDocument.firebaseAccess.serverTimestamp();
        }

        return fbObj;
    }
    private toFbObject(obj: any): any {
        for (const p of Object.keys(obj)) {
            const v = obj[p];
            if (v instanceof FbDocument) {
                obj[p] = v.getFbObject(false);
            } else if (v instanceof Date) {
                obj[p] = new Date(v);
            } else if (Array.isArray(v)) {
                v.forEach(o => this.toFbObject(o));
                obj[p] = v.map(o => Object.assign({}, o));
            } else if (isObject(v)) {
                this.toFbObject(v);
                obj[p] = Object.assign({}, v);
            }
        }
    }
    getConstructorFor(member: string): Constructor<any> | null {
        return null;
    }
}

export type Constructor<T> = new () => T;

export function getKeyValueConstructor<T>(c: Constructor<T>) {
    return class {
        getConstructorFor() {
            return c;
        }
    };
}

export function objectizeDocument<T extends FbDocument>(docId: string, data: any, constructor?: Constructor<T>): T {
    const obj = objectize(data, constructor);
    obj.id = docId;
    return obj;
}

function objectize<T>(data: any, constructor?: Constructor<T>): T {
    if (data instanceof Array) {
        for (let i = 0; i < data.length; i++) {
            data[i] = objectize(data[i], constructor);
        }

    } else if (isObject(data)) {
        if (constructor != null) {
            const newObj: any = new constructor();
            for (const memberName of Object.keys(data)) {
                const member = data[memberName];
                const newMember = newObj[memberName];
                if (member instanceof Array) {
                    for (let i = 0; i < member.length; i++) {
                        member[i] = objectize(member[i], getMemberConstructor(newObj, memberName, null));
                    }
                } else if (newMember == null && memberName === 'created') {
                    // Auto-added timestamp
                    data[memberName] = member;
                } else if (newMember instanceof Date) {
                    if (member != null && member.toDate != null) {
                        // Firebase TimeStamp
                        data[memberName] = member.toDate();
                    } else {
                        data[memberName] = new Date(member);
                    }
                } else {
                    data[memberName] = objectize(member, isObject(member) ? getMemberConstructor(newObj, memberName, newMember) : null);
                }
            }
            data = Object.assign(newObj, data);
            // console.log(constructor === Object.getPrototypeOf(obj).constructor);
            // console.log("post", obj, Object.getPrototypeOf(obj));
        } else {
            logWarning('Constructor not provided for JSON object:', data);
        }
    }
    return data;
}

function isObject(obj: any): boolean {
    return obj === Object(obj);
}

function getMemberConstructor(container: any, member: string, obj: any | null): any | null {
    let constructor = null;
    if (container.getConstructorFor != null) {
        constructor = container.getConstructorFor(member);
    }
    if (constructor == null && (obj != null && isObject(obj))) {
        constructor = Object.getPrototypeOf(obj).constructor;
    }
    return constructor;
}
