<ng-container *transloco="let t; read: 'admin'">
  <div appScrollTo *ngIf="bookings" class="container mat-elevation-z2 widget-background">
    <ng-container *ngIf="selectedBooking == null">
      <table id="bookingsTable" mat-table [dataSource]="bookings" matSort class="bookings">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t("bookings.table_name") }}
          </th>
          <td mat-cell *matCellDef="let b">
            {{ b.request.customer.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t("bookings.table_email") }}
          </th>
          <td mat-cell *matCellDef="let b">
            {{ b.request.customer.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t("bookings.table_date") }}
          </th>
          <td mat-cell *matCellDef="let b">
            {{ b.dateTime || b.request.dateTime | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t("bookings.table_location") }}
          </th>
          <td mat-cell *matCellDef="let b">
            {{ b.location || b.request.location }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t("bookings.table_status") }}
          </th>
          <td mat-cell *matCellDef="let b">
            <ng-container [ngSwitch]="b.status">
              <span *ngSwitchCase="'REQUESTED'">{{
                t("booking.status_requested")
              }}</span>
              <span *ngSwitchCase="'PROVIDER_ACCEPTED'">{{
                t("booking.status_provider_accepted")
              }}</span>
              <span *ngSwitchCase="'CONFIRMED'">{{
                t("booking.status_confirmed")
              }}</span>
              <span *ngSwitchCase="'CANCELED'">{{
                t("booking.status_canceled")
              }}</span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t("bookings.table_link") }}
          </th>
          <td mat-cell *matCellDef="let b">
            <a [routerLink]="['../', b.id]">{{ b.id }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="errors">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <b>!</b>
          </th>
          <td mat-cell *matCellDef="let b">
            {{ b.errors?.join(",") }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="bookingsTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: bookingsTableColumns"></tr>
      </table>

      <div
        class="unhandled"
        *ngIf="unhandledRequests != null && unhandledRequests.length > 0"
      >
        {{ t("bookings.unhandled_requests") }}:
        {{ unhandledRequests.join(",") }}
      </div>
    </ng-container>

    <ng-container *ngIf="selectedBooking != null">
      <a [routerLink]="['../', 'all']">{{ t("back") }}</a>
      <app-booking-details [booking]="selectedBooking" ></app-booking-details>
    </ng-container>
  </div>
</ng-container>
