<div id="component-calendar-root" *transloco="let t; read: 'component-calendar'">
  <div class="monthSelect">
    <button
      mat-icon-button
      class="xsmall-hide"
      (click)="prevNextMonth(false)"
      [attr.aria-label]="t('prev_month')"
    >
      <i class="material-icons md-button md-dark">keyboard_arrow_left</i>
    </button>

    <mat-form-field appearance="outline">
      <mat-select
        [value]="monthYear"
        (selectionChange)="this.setMonthYear($event.value)"
        [aria-label]="t('month_select')"
      >
        <mat-option *ngFor="let my of monthYearOptions" [value]="my">
          {{ my | date: "MMMM yyyy" }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-icon-button
      class="xsmall-hide"
      (click)="prevNextMonth(true)"
      [attr.aria-label]="t('next_month')"
    >
      <i class="material-icons md-button md-dark">keyboard_arrow_right</i>
    </button>
  </div>

  <table>
    <thead>
      <tr>
        <th *ngFor="let wd of weekDays">{{ wd | date: "EEE" }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let week of dateRows">
        <td *ngFor="let date of week">
          <div
            [ngClass]="{
              date: true,
              currentMonth: date.getMonth() == monthYear.getMonth(),
              inHistory: date <= today,
              'calendar-selected': currentSelectedDate === date,
              'calendar-tentative': isDateRequested(date),
              'calendar-blocked': isDateConfirmed(date)
            }"
            mat-ripple
            (click)="onDateSelect(date)"
          >
            {{ date | date: "d" }}
          </div>
        </td>
      </tr>
    </tbody>
    <!-- <tfoot>
      <tr><td colspan="7">Info here?</td></tr>
    </tfoot> -->
  </table>
</div>
