import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main.component';
import { BookingComponent } from './booking/booking.component';
import { FaqComponent } from './pages/faq.component';
import { ImageGalleryComponent } from './pages/image-gallery.component';
import { BookingsComponent } from './admin/bookings/bookings.component';
import { AdminDashboardComponent } from './admin/admin-dashboard.component';
import { AdminRouterComponent } from './admin/admin-router.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy.component';
import { VideoComponent } from './pages/video.component';

const routes: Routes = [
  { path: 'tellimine', component: BookingComponent },
  { path: 'kkk', component: FaqComponent },
  { path: 'video', component: VideoComponent },
  { path: 'pildid', component: ImageGalleryComponent },
  { path: 'privaatsus', component: PrivacyPolicyComponent },
  {
    path: 'admin',
    component: AdminRouterComponent,
    children: [
      { path: 'bookings/:id', component: BookingsComponent },
      { path: '', component: AdminDashboardComponent },
    ]
  },
  { path: '', component: MainComponent },
  { path: '**', component: MainComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
