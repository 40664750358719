<ng-container *ngIf="userService.isInitialized">
  <div class="container" [ngSwitch]="isSiteAdmin">
    <ng-container *ngIf="!userService.isLoggedIn">
    <p class="accent-contrast" *transloco="let t; read: 'admin'">{{t('login_info')}}</p>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <app-admin-login [style]="{ alignSelf: 'flex-center' }"></app-admin-login>
      <ng-container *ngIf="userService.siteAdminError">
      <p class="accent-contrast" *transloco="let t; read: 'admin'">{{t('request_admin_info')}}</p>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault> Wait </ng-container>
    <ng-container *ngSwitchCase="true">
      <app-admin-login [style]="{ alignSelf: 'flex-end' }"></app-admin-login>
      <router-outlet></router-outlet>
    </ng-container>
  </div>
</ng-container>
