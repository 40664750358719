<ng-container *transloco="let t; read: 'booking'">
  <div appScrollTo class="page-info-panel floating-header mat-elevation-z5">
    <h2>{{ t("title") }}</h2>
    <div class="mat-elevation-z2 accent-contrast">
      <app-formatted-text [text]="t('info')"></app-formatted-text>
    </div>
  </div>
  <div class="container">
    <ng-container *ngIf="createdRequest == null && services != null">
      <div
        *ngIf="services != null"
        class="services mat-elevation-z2 widget-background"
      >
        <ng-container
          *ngFor="let service of services; index as i; trackBy: trackBy"
        >
          <mat-divider *ngIf="i > 0"></mat-divider>
          <div
            #serviceCard
            [ngClass]="{
              'widget-selection mat-elevation-z1': selectedService == service,
              serviceCard: true
            }"
            mat-ripple
            matRippleDisabled="true"
          >
            <h4>{{ service.info[this.locale].name }}</h4>
            <div class="service">
              <div class="serviceImage"></div>
              <div class="serviceDescription">
                <app-formatted-text
                  [text]="service.info[this.locale].description"
                ></app-formatted-text>
                <div *ngIf="service.seasonPrices" class="serviceprice">
                  <i class="material-icons md-24 md-dark">euro</i>
                  <table>
                    <tbody>
                      <tr>
                        <td>{{t('regular_price')}}</td>
                        <td>
                          <strong>{{ service.price }}{{service.currency}}</strong>
                        </td>
                      </tr>
                      <tr *ngFor="let season of getSeasonPrices(service)">
                        <td *ngIf="season.from && season.to && season.price != null">
                          {{ season.from.day }}.{{ season.from.month }} -
                          {{ season.to.day }}.{{ season.to.month }}
                        </td>
                        <td>
                          <strong *ngIf="season.price > 0">{{ season.price }}{{service.currency}}</strong>
                          <strong *ngIf="season.price <= 0">{{ t('negotiated_price') }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <mat-divider vertical></mat-divider>
              <div class="serviceInfo">
                <span>{{ service.duration / 60 }}h</span>
                <span
                  ><b>{{ getServicePrice_(service) }}</b></span
                >
              </div>
            </div>

            <div [ngSwitch]="selectedService" class="serviceSelect">
              <button
                *ngSwitchCase="null"
                [id]="'app-booking-service-' + service.id"
                mat-raised-button
                color="primary"
                (click)="this.selectService(service)"
              >
                {{ t("select_service") }}
              </button>
              <button
                *ngSwitchDefault
                [id]="'app-booking-service-' + service.id"
                mat-stroked-button
                color="primary"
                (click)="this.selectService(service)"
              >
                {{ t("select_service") }}
              </button>
              <button
                [id]="'app-booking-service-' + service.id"
                mat-button
                disableRipple
                *ngSwitchCase="service"
                color="primary"
              >
                {{ t("selected_service") }}!
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <div
        [ngSwitch]="events"
        class="calendarContainer mat-elevation-z2 widget-background"
      >
        <h3 *ngSwitchCase="null">
          <span *ngIf="selectedService == null">{{
            t("pick_service_hdr")
          }}</span>
          <mat-progress-spinner
            *ngIf="selectedService"
            color="primary"
            mode="indeterminate"
            diameter="100"
          >
          </mat-progress-spinner>
        </h3>
        <ng-container *ngSwitchDefault>
          <h3>{{ t("pick_date_hdr") }}</h3>
          <app-calendar
            id="app-booking-calendar"
            [events]="events"
            [defaultEventHours]="defaultEventHours"
            (selectedDate)="selectDate($event)"
          ></app-calendar>

          <div class="calendarStatusInfo">
            <div class="calendar-tentative busyIndicator"></div>
            <div>{{ t("calendar_info_pending") }}</div>
          </div>
          <div class="calendarStatusInfo">
            <div class="calendar-blocked busyIndicator"></div>
            <div>{{ t("calendar_info_confirmed") }}</div>
          </div>
        </ng-container>

        <div *ngIf="selectedService != null && form.value.dateTime != null" class="selectedPrice">
          {{ t('selected_price') }} <strong>{{ getServicePrice_(selectedService) }}</strong>
        </div>
      </div>

      <ng-container *ngIf="form.value.dateTime != null">
        <form
          id="app-booking-form"
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          *transloco="let tForm; read: 'booking.form'"
          class="mat-elevation-z2 widget-background"
        >
          <h3>{{ t("booking_details_hdr") }}</h3>

          <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>{{ t("selected_date") }}</mat-label>
            <input
              readonly
              required
              formControlName="dateTime"
              matInput
              [matDatepicker]="dateTime"
            />
            <mat-datepicker-toggle
              hidden
              matSuffix
              [for]="dateTime"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateTime></mat-datepicker>
          </mat-form-field>

          <div formGroupName="customer" class="group">
            <mat-form-field appearance="outline">
              <mat-label>{{ tForm("name") }}</mat-label>
              <input
                required
                autocomplete="name"
                formControlName="name"
                id="name"
                matInput
                placeholder="{{ tForm('name') }}"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ tForm("email") }}</mat-label>
              <input
                required
                type="email"
                autocomplete="email"
                required
                formControlName="email"
                id="email"
                matInput
                placeholder="{{ tForm('email') }}"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ tForm("phone") }}</mat-label>
              <input
                required
                type="tel"
                autocomplete="tel"
                id="phone"
                formControlName="phone"
                matInput
                placeholder="{{ tForm('phone') }}"
              />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>{{ tForm("location") }}</mat-label>
            <input
              required
              formControlName="location"
              id="location"
              matInput
              placeholder="{{ tForm('location') }}"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ tForm("message") }}</mat-label>
            <textarea
              formControlName="message"
              id="message"
              matInput
              cdkTextareaAutosize
              rows="4"
              minRows="4"
              maxRows="10"
              placeholder="{{ tForm('message') }}"
            ></textarea>
            <mat-hint>{{ tForm("message_hint") }}</mat-hint>
          </mat-form-field>

          <app-formatted-text [text]="t('pre_submit_info')" textAlign="right"></app-formatted-text>

          <ng-container [ngSwitch]="this.form.disabled">
            <mat-progress-spinner
              *ngSwitchCase="true"
              color="primary"
              mode="indeterminate"
              diameter="40"
            >
            </mat-progress-spinner>
            <button
              *ngSwitchDefault
              id="app-booking-form-submit"
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="selectedService == null"
            >
              {{ tForm("submit") }}
            </button>
          </ng-container>
        </form>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="createdRequest != null">
      <div id="app-booking-success" class="mat-elevation-z2 widget-background">
        <p *ngFor="let p of getSuccessMessage(t('success_message'))">
          {{ p }}
        </p>
      </div>
    </ng-container>
  </div>

  <div class="page-info-panel floating-header mat-elevation-z5">
    <h2>{{ t("outro_title") }}</h2>
    <div class="mat-elevation-z2 accent-contrast">
      <app-formatted-text [text]="t('outro_info')"></app-formatted-text>
    </div>
  </div>
</ng-container>
