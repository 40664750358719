export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDhNUzo_k8qd3IKRC8CeCdBCsc18P20Clc',
    authDomain: 'emili.live',
    databaseURL: 'https://bandpit-f02a2.firebaseio.com',
    projectId: 'bandpit-f02a2',
    storageBucket: 'bandpit-f02a2.appspot.com',
    messagingSenderId: '696275505914',
    appId: '1:696275505914:web:75ce660f044d7555b96f43',
    measurementId: 'G-BR5G13L5VS'
  },
  functionsHost: '',
  functionsPort: 0,
  functionsEmulator: '',
  functionsRegion: 'europe-west1'
};
