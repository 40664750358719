<div class="app">
  <app-toolbar></app-toolbar>

  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <ng-container *transloco="let t; read: 'footer'">
    <footer class="floating-header">
      <div class="contacts">
        <div>{{ t("email") }}</div>
        <div>{{ t("phone") }}</div>
        <a routerLink="/privaatsus">{{ t("privacy_link") }}</a>
        <a [href]="fbShareLink">{{ t("fb_share_link") }}</a>
      </div>
    </footer>
  </ng-container>

  <div
    #fbtrigger
    class="fb-like"
    [attr.data-href]="fbPage"
    data-layout="button_count"
    data-action="like"
    data-size="small"
    data-share="true"
  ></div>
</div>
