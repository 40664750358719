
import { isPlatformBrowser } from '@angular/common';
import { Directive, Input, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';

function smoothScrollTo(top: number, platformId: string) {
  try {
    window.scrollTo({ top, behavior: null });
  } catch (err) {
    window.scrollTo(0, top);
  }
}

function scrollIntoView(element: HTMLElement, platformId: string) {
  const TOOLBAR_HEIGHT = 0;
  element.scrollIntoView();
  const scrollY = window.scrollY || document.documentElement.scrollTop;
  smoothScrollTo(scrollY - TOOLBAR_HEIGHT, platformId);
}

@Directive({
  selector: '[appScrollTo]'
})
export class ScrollToDirective implements AfterViewInit {
  @Input() scrollToElement: HTMLElement;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string) { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.scrollToElement) {
        scrollIntoView(this.scrollToElement, this.platformId);
      } else {
        document.body.scrollIntoView(true);
      }
    }
  }
}