import Image from './Image';
import { FbDocument, getKeyValueConstructor } from './FbDocument';

export default class Faq extends FbDocument {
    pos = 0;
    qa: { [locale: string]: QA } = {};
    private firestore_image: string | null = null;
    private image: Image | null;
    group: string | null = null;
    hidden: boolean = false;

    getConstructorFor(member: string) {
        if (member === 'qa') {
            return getKeyValueConstructor(QA);
        }
        return super.getConstructorFor(member);
    }

    getImage(): Image | null {
        if (this.image == null)
            this.image = this.firestore_image != null ? new Image({src: this.firestore_image, firestore: 'true'}) : null;
        return this.image;
    }
}

export class QA {
    q = '';
    a = '';
}

export class FaqStructure {
    subs: FaqStructure[] = [];
    constructor(public faq: Faq) { }
    sort(sorter: (s0: FaqStructure, s1: FaqStructure) => number) {
        this.subs.sort(sorter);
    }
}

export function getFaqStructure(faqs: Faq[]): FaqStructure[] {
    const structures: FaqStructure[] = [];
    const rem = [...faqs];
    let remSize = rem.length;
    do {
        for (const f of [...rem]) {
            if (f.hidden)
                continue;
            if (f.group == null) {
                rem.splice(rem.indexOf(f), 1);
                structures.push(new FaqStructure(f));
            } else {
                const parent = structures.find(s => s.faq.id === f.group);
                if (parent != null) {
                    rem.splice(rem.indexOf(f), 1);
                    parent.subs.push(new FaqStructure(f));
                }
            }
        }
        if (remSize === rem.length) {
            // Nothing processed, no need to continue
            break;
        }
        remSize = rem.length;
    } while (rem.length > 0);

    const sorter = (s0: FaqStructure, s1: FaqStructure) => s0.faq.pos - s1.faq.pos;
    structures.sort(sorter);
    structures.forEach(s => s.sort(sorter));

    return structures;
}
