import { FbDocument } from './FbDocument';
import Customer from './Customer';

export default class CustomerRequest extends FbDocument {
    customer = new Customer();
    message = '';
    handled = false;
    constructor(public kind: string,
        public language: string) {
        super();
    }
}
