import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Pages, PagesService } from '../core/pages.service';
import Image from '../data/Image';
import { SubscriberComponent } from '../shared/subscriber.component';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent extends SubscriberComponent implements OnInit, AfterViewInit {

  introImgSrc: Image | null;
  videoThumbnail: string | null;

  members: Member[] | null;

  @ViewChild('hiddenPrimary') hiddenPrimary: ElementRef;

  soundCloudWidgetEnabled = true;
  soundCloudWidgetContent = false;
  soundCloudWidgetLoaded = false;
  blankUrl: SafeUrl | null = null;
  soundcloudWidgetUrl: SafeUrl | null;
  primaryColorHex: string | null;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private pagesService: PagesService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: string) {
    super();
    this.blankUrl = this.sanitizer.bypassSecurityTrustResourceUrl("about:blank");
    // this.soundCloudWidgetEnabled = environment.production && isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.pagesService.setPageMeta(Pages.main, this.locale);
    this.pagesService.getPage(Pages.main, this.locale)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(page => {

        this.introImgSrc = page.images.intro;

        // Magic format warning :/
        const members = [];
        for (const m of ['bass', 'drums', 'keyboard', 'rhythm_guitar', 'vocals', 'lead_guitar']) {
          const mText = page.texts.members[m];
          const member = new Member(m, mText.name, mText.description, page.images[`members_${m}`]);
          members.push(member);
        }
        this.members = members;

        const scUrl = encodeURIComponent(page.settings.soundcloud_widget_url);
        this.soundcloudWidgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://w.soundcloud.com/player/?url=${scUrl}&color=%23${this.primaryColorHex}`);

        this.changeDetector.markForCheck();
      });
      this.pagesService.getVideos()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          videos => {
            const video = videos.find(v => v.visible && v.thumbnailUrl.length > 0);
            if (video != null) {
              this.videoThumbnail = video.thumbnailUrl[0];
              this.changeDetector.markForCheck();
            }
          }
        );
  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const rgb = getComputedStyle(this.hiddenPrimary.nativeElement).color;
    const rgbMatch = /^rgb\(([0-9]+),\s*([0-9]+),\s*([0-9]+)\)$/.exec(rgb);
    if (rgbMatch != null && rgbMatch.length >= 4) {
      rgbMatch.splice(0, 1);
      this.primaryColorHex = rgbMatch.map(dec => Number(dec).toString(16)).join('');
    } else {
      this.primaryColorHex = '924343';
    }
  }

  trackBy(index: number, member: Member) {
    return member.id;
  }

  showSoundCloudWidgetContent() {
    this.soundCloudWidgetContent = true
    this.changeDetector.markForCheck();
  }

  audioLoaded(event: any) {
    if (this.soundCloudWidgetContent) {
      this.soundCloudWidgetLoaded = true;
      this.changeDetector.markForCheck();
    }
  }

}

class Member {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public image: Image) { }
}
