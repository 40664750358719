<ng-container *transloco="let t; read: 'footer'">
  <ng-container *ngIf="auth.user | async as user; else showLogin">
    <div class="GoogleLogin">
      <span>{{ user.displayName }}</span>
      <button mat-button color="primary" (click)="logout()">
        {{ t("log_out") }}
      </button>
    </div>
  </ng-container>
  <ng-template #showLogin>
    <button mat-button class="GoogleLogin" (click)="login()">
      <img src="/assets/images/btn_google_signin_light_normal_web@2x.png" />
    </button>
  </ng-template>
</ng-container>
