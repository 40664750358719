import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Image from '../data/Image';

@Directive({
  selector: '[appFirestoreImage]'
})
export class FirestorageImageDirective implements OnChanges, OnDestroy {

  // TODO custom file name mapping

  @Input('appFirestoreImage')
  private ref: Image | null;

  @HostBinding('src')
  imageSrc: string;

  @HostBinding('attr.width')
  imageWidth?: number;
  @HostBinding('attr.height')
  imageHeight?: number;

  @HostBinding('attr.aria-label')
  imageName: string;
  @HostBinding('alt')
  imageAlt: string;

  imgSrc: Observable<string> | null;
  imgSrcSubscription: Subscription | null;

  ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private storage: AngularFireStorage) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ref != null) {
      const ref: Image | null = changes.ref.currentValue;
      if (ref != null) {
        if (ref.width != null)
          this.imageWidth = ref.width;
        if (ref.height != null)
          this.imageHeight = ref.height;

        if (ref.local) {
          this.imageSrc = "/assets/" + ref.src;

        } else if (!ref.firestore) {
          this.imageSrc = ref.src;

        } else {
          if (this.imgSrcSubscription != null) {
            this.imgSrcSubscription.unsubscribe();
          }

          this.imgSrcSubscription = this.storage.ref(ref.src).getDownloadURL()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (urlStr: string) => {

                // Remove token parameter as it is not needed
                var url = new URL(urlStr);
                var params = new URLSearchParams(url.search);
                params.delete('token');
                url.search = params.toString();
                urlStr = url.toString();

                this.imageSrc = urlStr;
              },
              error: error => console.error(`Image not found ${ref.src}`)
            });
        }

        try {
          this.imageName = this.imageAlt = getFirestoreImageName(ref);
        } catch (err) { }
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(1);
    this.ngUnsubscribe.complete();
  }

}

export function getFirestoreImageName(ref: Image): string {
  if (ref.alt != null)
    return ref.alt;
  const path = ref.src.split('/');
  return path[path.length - 1].split('.')[0];
}