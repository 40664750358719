
export default class Image {
    public src: string;
    public thumbSrc?: string;
    public local: boolean;
    public firestore: boolean;
    public width: number | null;
    public height: number | null;
    public alt: string | null;
    private thumb: Image | null;

    constructor(image?: Map<string>) {
        // Could be undefined for copy-constructor
        if (image != null) {
            this.src = image.src;
            this.thumbSrc = image.thumbSrc;
            this.local = Boolean(image.local) || false;
            this.firestore = Boolean(image.firestore) || false;
            this.width = image.width ? Number(image.width) : null;
            this.height = image.height ? Number(image.height) : null;
            this.alt = image.alt || null;
        }
    }

    getConstructorFor(member: string) {
        if (member === 'thumb') {
            return Image;
        }
        return null;
    }

    getThumb(): Image {
        if (this.thumb == null) {
            let thumbSrc = !this.firestore ? this.thumbSrc || this.src : null;
            if (thumbSrc == null) {
                const path = this.src.split('/');
                const name = path[path.length - 1];
                path.splice(path.length - 1, 1);
                path.push('thumbs');
                path.push(name);
                thumbSrc = path.join('/');
            }
            this.thumb = new Image({ src: thumbSrc });
            this.thumb.local = this.local;
            this.thumb.firestore = this.firestore;
            this.thumb.width = this.width;
            this.thumb.height = this.height;
            this.thumb.alt = this.alt;
        }
        return this.thumb;
    }
}

interface Map<T> {
    [key: string]: T;
  }