import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, ɵgetDOM } from '@angular/common';
import { PrebootModule } from 'preboot';

import '@angular/common/locales/global/et';

import { AppRoutingModule } from './app-routing.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateModule } from '@angular/material/core';

import { TRANSLATION_MODULES } from './transloco-root.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { serverTimestamp } from 'firebase/firestore';
import 'firebase/storage';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BookingModule } from './booking/booking.module';
import { AppComponent } from './app.component';
import { dateFormats, LocaleParsingDateAdapter } from './util/dates';
import { FbDocument } from './data/FbDocument';
import { ToolbarModule } from './toolbar/toolbar.module';
import { PagesModule } from './pages/pages.module';
import { AdminModule } from './admin/admin.module';

const currentLocale = 'et';

FbDocument.firebaseAccess = {
  serverTimestamp
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    PrebootModule.withConfig({ appRoot: 'app-root' }),
    BrowserTransferStateModule,
    CoreModule,
    AppRoutingModule,
    MatDatepickerModule,
    NativeDateModule,
    SharedModule,
    ToolbarModule,
    BookingModule,
    PagesModule,
    AdminModule,
    MatButtonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: currentLocale
    },
    {
      provide: TRANSLATION_MODULES,
      useValue: ['header', 'footer', 'main', 'booking', 'component-calendar', 'common']
    },
    {
      provide: DateAdapter, useClass: LocaleParsingDateAdapter
    },
    { provide: MAT_DATE_FORMATS, useValue: dateFormats },
    { provide: BUCKET, useValue: environment.firebase.storageBucket },
    // Workaround for Preboot - see https://github.com/angular/preboot/issues/120
    {
      provide: APP_INITIALIZER,
      useFactory: (document: HTMLDocument, platformId: string) => {
        return () => {
          if (isPlatformBrowser(platformId)) {
            const styles: any[] = Array.prototype.slice.apply(document.querySelectorAll('style[ng-transition]'));
            styles.forEach(el => {
              // Remove ng-transition attribute to prevent Angular appInitializerFactory
              // to remove server styles before preboot complete
              el.removeAttribute('ng-transition');
            });
            document.addEventListener('PrebootComplete', () => {
              // After preboot complete, remove the server scripts
              const dom = ɵgetDOM();
              setTimeout(() => styles.forEach(el => dom.remove(el)));
            });
          }
        };
      },
      deps: [DOCUMENT, PLATFORM_ID],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
