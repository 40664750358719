import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Pages, PagesService } from '../core/pages.service';
import { UserService } from '../core/user.service';
import { SubscriberComponent } from '../shared/subscriber.component';

@Component({
  selector: 'app-admin-router',
  templateUrl: './admin-router.component.html',
  styles: [`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    padding: 1em;
  }
  `]
})
export class AdminRouterComponent extends SubscriberComponent implements OnInit {

  isSiteAdmin: boolean | null = null;

  constructor(
    private router: Router,
    public userService: UserService,
    private pageService: PagesService,
    @Inject(LOCALE_ID) public locale: string) {
    super();
  }

  ngOnInit(): void {
    this.userService.isSiteAdmin().pipe(takeUntil(this.ngUnsubscribe)).subscribe(is => this.isSiteAdmin = is);
    this.pageService.setPageMeta(Pages.admin, this.locale, {noindex: true});
  }

}
