import { FbDocument } from './FbDocument';

export class VideoPage extends FbDocument {
    videos: VideoInfo[] = [];

    getConstructorFor(member: string) {
        if (member === 'videos') {
            return VideoInfo;
        }
        return super.getConstructorFor(member);
    }
}

export class VideoInfo {
    contentUrl = '';
    description = '';
    name = '';
    thumbnailUrl: string[] = [];
    uploadDate = '';
    visible = true;
}