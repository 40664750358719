import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator, Functions } from 'firebase/functions';
import 'firebase/functions';

const app = initializeApp(environment.firebase);

@Injectable()
export class CloudFunctionsService {
    public functions: Functions;
    constructor() {
        this.functions = getFunctions(app, environment.functionsRegion);
        if (!environment.production) {
            // connectFunctionsEmulator(this.functions, environment.functionsHost, environment.functionsPort);
        }
    }
}
