import CustomerRequest from './CustomerRequest';
import ServiceInfo from './ServiceInfo';

export const BOOKING_REQUEST = 'booking';

export default class BookingRequest extends CustomerRequest {
  service: ServiceInfo = new ServiceInfo();
  location = '';
  dateTime: Date = new Date();
  constructor(language = 'et') {
    super(BOOKING_REQUEST, language);
  }
}
