import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Pages, PagesService } from '../core/pages.service';

@Component({
  selector: 'app-privacy-policy',
  template: `
<ng-container *transloco="let t; read: 'policy'">
  <div
    *ngIf="t('title') != ' ' || t('info') != ' '"
    class="page-info-panel floating-header mat-elevation-z5"
  >
    <h2 >{{ t("title") }}</h2>
    <div class="mat-elevation-z2 accent-contrast">
      <app-formatted-text [text]="t('privacy')"></app-formatted-text>
    </div>
  </div>
</ng-container>`,
  styles: [`
  `]
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private pagesService: PagesService,
    @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit(): void {
    this.pagesService.setPageMeta(Pages.policy, this.locale, {noindex: true});
  }

}
