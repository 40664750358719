<ngx-json-ld [json]="jsonLdSchema"></ngx-json-ld>
<ng-container *transloco="let t; read: 'video'">
  <div *ngIf="t('title') != ' ' || t('info') != ' '" appScrollTo class="page-info-panel floating-header mat-elevation-z5">
    <h2 *ngIf="t('title') != ' '">{{ t("title") }}</h2>
    <div *ngIf="t('info') != ' '" class="mat-elevation-z2 accent-contrast">
      <app-formatted-text [text]="t('info')"></app-formatted-text>
    </div>
  </div>
</ng-container>
<div class="container">
  <ng-container *ngIf="videos != null">
    <section
      *ngFor="let v of videos; trackBy: trackBy"
      class="mat-elevation-z2 widget-background"
    >
      <!-- <h2>{{ v.name }}</h2> -->
      <app-formatted-text [text]="v.description"></app-formatted-text>
      <iframe
        [width]="v.frameWidth || 560"
        [height]="v.frameHeight || 314"
        [src]="safeUrls.get(v.contentUrl)"
        [title]="v.name"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </section>
  </ng-container>
</div>
