import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Pages, PagesService } from '../core/pages.service';
import { SubscriberComponent } from '../shared/subscriber.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends SubscriberComponent implements OnInit {
  logoImgFirestorageRef: string | null;

  constructor(
    public router: Router,
    @Inject(LOCALE_ID) public locale: string,
    private pagesService: PagesService) {
    super();
  }

  ngOnInit(): void {
    this.pagesService.getPage(Pages.header, this.locale)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(header => {
        // 'images/Emili.jpg'
        this.logoImgFirestorageRef = header.images.header_logo.src;
      });
  }

}
