<ng-container *transloco="let t; read: 'admin'">
  <table class="booking">
    <tr>
      <td>{{ t("bookings.table_name") }}:</td>
      <td>{{ booking.request.customer.name }}</td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_email") }}:</td>
      <td>{{ booking.request.customer.email }}</td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_phone") }}:</td>
      <td>{{ booking.request.customer.phone }}</td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_date") }}:</td>
      <td>
        {{ booking.dateTime || booking.request.dateTime | date }}
      </td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_location") }}:</td>
      <td>
        {{ booking.location || booking.request.location }}
      </td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_message") }}:</td>
      <td>{{ booking.request.message }}</td>
    </tr>

    <tr>
      <td colspan="2"><mat-divider></mat-divider></td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_service") }}:</td>
      <td>
        {{ getServiceName(booking) }}
      </td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_price") }}:</td>
      <td>
        <span>
          {{ booking.accounting.price + booking.accounting.transportFee }}
          ({{ booking.accounting.price }} +
          {{ booking.accounting.transportFee }})
          {{ booking.request.service.currency }}</span
        >
      </td>
    </tr>
    <!-- <tr>
          <td>{{ t("bookings.table_created") }}:</td>
          <td>
            {{ booking.created | date }}
          </td>
        </tr> -->

    <tr>
      <td colspan="2"><mat-divider></mat-divider></td>
    </tr>
    <tr>
      <td>{{ t("bookings.table_status") }}:</td>
      <td>
        <ng-container [ngSwitch]="booking.status">
          <span *ngSwitchCase="'REQUESTED'">{{
            t("booking.status_requested")
          }}</span>
          <span *ngSwitchCase="'PROVIDER_ACCEPTED'">{{
            t("booking.status_provider_accepted")
          }}</span>
          <span *ngSwitchCase="'CONFIRMED'">{{
            t("booking.status_confirmed")
          }}</span>
          <span *ngSwitchCase="'CANCELED'">{{
            t("booking.status_canceled")
          }}</span>
        </ng-container>
      </td>
    </tr>
  </table>

  <div class="actions">
    <div class="action">
      <div class="actionLabel">{{ t("booking.set_new_status") }}:</div>
      <div class="actionInput">
        <mat-form-field appearance="outline">
          <mat-select
            [value]="booking.status"
            (selectionChange)="setStatus($event.value)"
            [aria-label]="t('booking.set_new_status')"
          >
            <mat-option
              *ngFor="let newStatus of bookingStatuses"
              [value]="newStatus"
            >
              <ng-container [ngSwitch]="newStatus">
                <ng-container *ngSwitchCase="'REQUESTED'">{{
                  t("booking.status_requested")
                }}</ng-container>
                <ng-container *ngSwitchCase="'PROVIDER_ACCEPTED'">{{
                  t("booking.status_provider_accepted")
                }}</ng-container>
                <ng-container *ngSwitchCase="'CONFIRMED'">{{
                  t("booking.status_confirmed")
                }}</ng-container>
                <ng-container *ngSwitchCase="'CANCELED'">{{
                  t("booking.status_canceled")
                }}</ng-container>
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
