import { FbDocument } from './FbDocument';
import ServiceInfo from './ServiceInfo';
import Accounting from './Accounting';
import BookingRequest from './BookingRequest';

export enum BookingStatus {
    REQUESTED = 'REQUESTED',
    PROVIDER_ACCEPTED = 'PROVIDER_ACCEPTED',
    CONFIRMED = 'CONFIRMED',
    CANCELED = 'CANCELED'
}

export enum BookingHandlingStatus {
    CUSTOMER_EMAIL_SENT = 'CUSTOMER_EMAIL_SENT',
    ORGANIZERS_EMAIL_SENT = 'ORGANIZERS_EMAIL_SENT',
}

export default class Booking extends FbDocument {
    request: BookingRequest = new BookingRequest('');

    // Optional overrides for request
    service: ServiceInfo | null = null;
    location: string | null = null;
    dateTime: Date | null = null;

    accounting: Accounting = new Accounting();
    status = BookingStatus.REQUESTED;

    calendarEventId = '';
    handlingStatus: string[] = [];

    errors: string[] | null = null;
}