import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Pages, PagesService } from '../core/pages.service';
import { FaqStructure, getFaqStructure, QA } from '../data/Faq';
import { SubscriberComponent } from '../shared/subscriber.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends SubscriberComponent implements OnInit {

  faqs: FaqStructure[] | null;

  constructor(
    private pagesService: PagesService,
    @Inject(LOCALE_ID) public locale: string) {
    super();
  }

  ngOnInit(): void {
    this.pagesService.setPageMeta(Pages.faq, this.locale);
    this.pagesService.getFaqs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        faqs => {
          this.faqs = getFaqStructure(faqs);
        }
      );
  }

  getQA(faq: FaqStructure): QA {
    return faq.faq.qa[this.locale];
  }

  trackBy(index: number, object: FaqStructure): any {
    return object.faq.id;
  }

}
