import {Component, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export abstract class SubscriberComponent implements OnDestroy {

  ngUnsubscribe: Subject<any> = new Subject();

  ngOnDestroy() {
    this.ngUnsubscribe.next(1);
    this.ngUnsubscribe.complete();
  }
}
