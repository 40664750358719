import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { merge, Observable, combineLatest, Subscription } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { objectizeDocument } from '../data/FbDocument';
import { CloudFunctionsService } from './cloud-functions.service';
import Faq from '../data/Faq';
import Image from '../data/Image';
import { GalleryPage, GalleryImage } from '../data/GalleryImage';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Meta, Title, MetaDefinition, TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { VideoInfo, VideoPage } from '../data/Video';
// import { isPlatformBrowser } from '@angular/common';

enum States {
  pages = 'pages',
  faq = 'faq',
  galleryImages = 'galleryImages',
  videos = 'videos'
}

export enum Pages {
  header = 'header',
  main = 'main',
  booking = 'booking',
  faq = 'faq',
  video = 'video',
  gallery = 'gallery',
  policy = 'policy',
  admin = 'admin'
}

@Injectable({
  providedIn: 'root',
})
export class PagesService {

  private metaSetter: Subscription | null;

  constructor(
    private firestore: AngularFirestore,
    private fs: CloudFunctionsService,
    private storage: AngularFireStorage,
    @Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private titleService: Title,
    private metaService: Meta
  ) {
    // Set Cahce-Cotnrol headers for storage files

    // Gallery images
    // this.firestore.collection('pages').doc('gallery').get().subscribe(doc => {
    //   const images = (doc.data() as GalleryPage).images;
    //   const imagePaths = images.map(img => img.firestore_image);
    //   imagePaths.push.apply(imagePaths, images.map(img => this.getGalleryImageThumb(img)));
    //   this.setCacheControl(imagePaths);
    // });

    // Other site images
    // this.storage.ref('images').listAll().subscribe(all => this.setCacheControl(all.items.map(i => i.fullPath)));

    // Create backup
    // const fbCallable = this.fs.functions.httpsCallable('createBackup');
    // fbCallable(null).then(data => console.log(data));
  }

  setCacheControl(paths: string[]) {
    for (const path of paths) {
      this.storage.ref(path).getMetadata().subscribe(meta => console.log(meta));
      this.storage.ref(path).updateMetadata({
        // 3 months
        cacheControl: 'public, max-age=7776000'
      }).subscribe(result => console.log(result), error => console.error(error));
    }
  }

  correctStructure() {
    /*
    this.firestore.collection('faq').get().subscribe(docs => {
      docs.forEach(doc => {
        const data: any = doc.data();
        const newData: any = {
          qa: { et: data.et }
        };
        if (data.group)
          newData.group = data.group;
        if (data.firestore_image)
          newData.firestore_image = data.firestore_image;
        // console.log(doc.id, newData);
        // doc.ref.set(newData);
      });
    });
    */
  }

  setPageMeta(pageId: string, locale: string, options?: { titleSuffix?: string, noindex?: boolean }) {

    if (this.metaSetter != null) {
      this.metaSetter.unsubscribe();
    }

    const defaultMetaPageId = Pages.main;

    let observable: Observable<Page | Page[]> = this.getPage(pageId, locale);
    if (pageId !== defaultMetaPageId) {
      observable = combineLatest([observable as Observable<Page>, this.getPage(defaultMetaPageId, locale)]);
    }

    this.metaSetter = observable.subscribe((result: Page | Page[]) => {
      let page: Page;
      let defaultPage: Page;
      if (Array.isArray(result)) {
        page = result[0];
        defaultPage = result[1];
      } else {
        page = defaultPage = result;
      }

      let title = page.meta.title || defaultPage.meta.title || '';
      if (options?.titleSuffix != null) {
        title += ' | ' + options.titleSuffix;
      }
      this.titleService.setTitle(title);

      if (options?.noindex) {
        this.metaService.updateTag({
          name: 'robots',
          content: 'noindex'
        });
      } else {
        this.metaService.removeTag('name=robots');
      }

      this.metaService.updateTag({
        name: 'description',
        content: page.meta.description || defaultPage.meta.description
      });
    });
  }

  getFaqs(): Observable<Faq[]> {
    const transferKey = makeStateKey<any>('Pages.' + States.faq);
    const transferData: Faq[] | null = this.transferState.get<Faq[]>(transferKey, null);

    return this.firestore.collection<Faq>('faq').valueChanges({ idField: 'id' })
      .pipe(
        startWith(transferData),
        filter((faq: Faq[] | null) => faq != null),
        tap((faq: Faq[]) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(transferKey, faq);
          }
        }),
        map(
          faqs => faqs.map(faq => objectizeDocument(faq.id, faq, Faq))
        )
      );
  }

  getGalleryImages(): Observable<GalleryImage[]> {
    const transferKey = makeStateKey<any>('Pages.' + States.galleryImages);
    const transferData: GalleryPage | null = this.transferState.get<GalleryPage>(transferKey, null);

    return this.firestore.collection<GalleryPage>('pages').doc('gallery').valueChanges({ idField: 'id' })
      .pipe(
        startWith(transferData),
        filter((page: GalleryPage | null) => page != null),
        tap((page: GalleryPage) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(transferKey, page);
          }
        }),
        map((page: GalleryPage) => objectizeDocument(page.id, { images: page.images }, GalleryPage).images));
  }

  getGalleryImageThumb(image: GalleryImage): Image {
    return image.getImage().getThumb();
  }

  getVideos(): Observable<VideoInfo[]> {
    const transferKey = makeStateKey<any>('Pages.' + States.videos);
    const transferData: VideoPage | null = this.transferState.get<VideoPage>(transferKey, null);

    return this.firestore.collection<VideoPage>('pages').doc('video').valueChanges({ idField: 'id' })
      .pipe(
        startWith(transferData),
        filter((page: VideoPage | null) => page != null),
        tap((page: VideoPage) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(transferKey, page);
          }
        }),
        map((page: VideoPage) => objectizeDocument(page.id, { videos: page.videos }, VideoPage).videos));
  }

  getPage(pageId: string, locale: string): Observable<Page> {

    const transferKey = makeStateKey<any>('Pages.' + States.pages + '.' + pageId);
    const transferData: any | null = this.transferState.get(transferKey, null);

    return this.firestore.collection('pages').doc(pageId).valueChanges({ idField: 'id' }).pipe(
      startWith(transferData),
      filter((page: any | null) => page != null),
      tap((page: any) => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(transferKey, page);
        }
      }),
      map((page: any) => {
        const texts = page[locale];
        return new Page(
          locale,
          page.firestore_images,
          page.images,
          page.settings,
          // Some page documents are not actually pages (e.g. header) thus no meta
          new MetaInfo(texts.meta?.title, texts.meta?.description),
          texts
        );
      })
    );
  }
}

interface Map<T> {
  [key: string]: T;
}

class MetaInfo {
  constructor(public title: string,
    public description: string) { }
}

class Page {
  public images: Map<Image> = {};
  constructor(
    public locale: string,
    firestore_images: Map<string>,
    images_str: Map<Map<string>>,
    public settings: Map<string>,
    public meta: MetaInfo,
    public texts: any) {
    if (images_str != null) {
      for (const i in images_str)
        this.images[i] = new Image(images_str[i]);
    }
    if (firestore_images != null) {
      for (const i in firestore_images)
        if (this.images[i] == null)
          this.images[i] = new Image({ src: firestore_images[i], firestore: 'true' });
    }
  }
}
