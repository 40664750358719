import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CloudFunctionsService } from '../core/cloud-functions.service';
import { map } from 'rxjs/operators';

export enum SiteAdminErrors {
  permissionDenied = 'permissionDenied'
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  firebaseUser: Observable<User>;
  siteAdmin: BehaviorSubject<SiteAdmin | null>;

  public isInitialized = false;
  public isLoggedIn = false;
  public siteAdminError: SiteAdminErrors | null = null;

  constructor(
    public auth: AngularFireAuth,
    private fs: CloudFunctionsService) {
    this.firebaseUser = this.auth.user;
  }

  getUser(): Observable<User> {
    return this.firebaseUser;
  }

  getSiteAdmin(): Observable<SiteAdmin | null> {
    if (this.siteAdmin == null) {
      this.siteAdmin = new BehaviorSubject<SiteAdmin | null>(null);
      this.getUser().subscribe(user => {
        this.isLoggedIn = user != null;
        this.siteAdminError = null;
        if (user != null) {
          const fbCallable = httpsCallable(this.fs.functions, 'getAdminUser');
          fbCallable(null)
            .then(data => {
              this.isInitialized = true;
              const siteAdmin = (data.data as any).siteAdmin as SiteAdmin;
              this.siteAdmin.next(siteAdmin);
            })
            .catch(err => {
              this.isInitialized = true;
              if (err.code === 'permission-denied') {
                this.siteAdminError = SiteAdminErrors.permissionDenied;
                this.siteAdmin.next(null);
              } else {
                throw err;
              }
            });
        } else {
          this.isInitialized = true;
          this.siteAdmin.next(null);
        }
      });
    }
    return this.siteAdmin;
  }

  isSiteAdmin(): Observable<boolean> {
    return this.getSiteAdmin().pipe(map(siteAdmin => siteAdmin != null));
  }
}

class SiteAdmin {
  constructor(public root: boolean) { }
}
