import Image from './Image';
import { FbDocument, getKeyValueConstructor } from './FbDocument';

export class GalleryPage extends FbDocument {
    images: GalleryImage[] = [];

    getConstructorFor(member: string) {
        if (member === 'images') {
            return GalleryImage;
        }
        return super.getConstructorFor(member);
    }
}

export class GalleryImage {
    visible = true;
    pos = 0;
    private firestore_image: string | null = null;
    private ext_image: string | null = null;
    private ext_thumb: string | null = null;
    info: { [locale: string]: Info } = {};
    // Initialized locally
    private image: Image | null;

    getConstructorFor(member: string) {
        if (member === 'info') {
            return getKeyValueConstructor(Info);
        }
        if (member === 'image') {
            return Image;
        }
        return null;
    }

    getImage(): Image | null {
        if (this.image == null) {
            if (this.ext_image != null)
                this.image = new Image({ src: this.ext_image, thumbSrc: this.ext_thumb });
            else if (this.firestore_image != null)
                this.image = new Image({ src: this.firestore_image, firestore: 'true' });
        }
        return this.image;
    }
}

class Info {
    name = '';
    description = '';
}
