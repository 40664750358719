import ServiceInfo from '../data/ServiceInfo';

export function getServicePrice(service: ServiceInfo, date: Date | null): number {
    if (service.seasonPrices != null) {
        if (date == null)
            // Current date
            date = new Date();
        for (const seasonal of service.seasonPrices) {
            const dMonth = date.getMonth() + 1;
            const dDay = date.getDate();
            if ((seasonal.from.month < dMonth || (seasonal.from.month == dMonth && seasonal.from.day <= dDay)) &&
            (seasonal.to.month > dMonth || (seasonal.to.month === dMonth && seasonal.to.day >= dDay))) {
                return seasonal.price;
            }
        }
    }
    return service.price;
}