import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-formatted-text',
  template: `<p *ngFor="let l of lines" [style]="{'text-align': textAlign}" [innerHTML]="l" (click)="processLinks($event)"></p>`,
  styles: [`
  i {
    vertical-align: text-top;
  }`
  ]
})
export class FormattedTextComponent implements OnChanges {

  @Input()
  public text: string;

  @Input()
  textAlign: string | null;

  public lines: string[] = [];

  constructor(
    private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text != null && changes.text.currentValue != null) {
      let newLines = (changes.text.currentValue as string).split('\\n');
      const smileyReplacement = `<i class="material-icons md-18 md-dark">sentiment_satisfied_alt</i>`;
      newLines = newLines.map(l => l.replace(';)', smileyReplacement));
      newLines = newLines.map(l => l.replace(':)', smileyReplacement));
      this.lines = newLines;
    }
  }

  processLinks(e: Event) {
    const element: HTMLElement = e.target as HTMLElement;
    if (element.hasAttribute("href")) {
      const href = element.getAttribute("href");
      this.router.navigate([href]);
      e.preventDefault();
    }
  }

}
