import { environment } from 'src/environments/environment';
import ServiceInfo from '../data/ServiceInfo';

export function logWarning(message?: any, ...optionalParams: any[]) {
    if (!environment.production) {
        console.warn('WARNING:', message, optionalParams);
    }
}

export function formatTranslation(text: string, replacements?: { [key: string]: string }): string[] {
    if (replacements != null) {
        for (const key of Object.keys(replacements)) {
            text = text.replace(key, replacements[key]);
        }
    }
    return text.split('\\n');
}