import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SubscriberComponent } from './shared/subscriber.component';

declare const FB: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  // TODO
  language = 'et_EE';
  appId = '557067672088854';
  domain = 'https://emili.live';
  fbPage = 'https://www.facebook.com/bandEmili';
  fbShareLink = `https://www.facebook.com/dialog/share?app_id=${this.appId}&display=page&href=${this.domain}&redirect_uri=${this.domain}`;

  @ViewChild('fbtrigger')
  fbTrigger: ElementRef;

  constructor(
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: string) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => this.addFooterObserver(), 3000);
    });
  }

  addFooterObserver() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.fbTrigger != null) {
        const footerDiv = this.fbTrigger.nativeElement;
        const observer = new IntersectionObserver(
          (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            for (const e of entries) {
              if (e.isIntersecting) {
                this.loadFacebook();
                observer.disconnect();
                break;
              }
            }
          },
          {
            rootMargin: '100%'
          }
        );
        observer.observe(footerDiv);

      } else
        this.loadFacebook();
    }
  }

  loadFacebook() {
    if (isPlatformBrowser(this.platformId)) {

      (window as any).fbAsyncInit = () => {
        FB.init({
          appId: this.appId,
          version: 'v11.0',
          autoLogAppEvents: false,
          xfbml: false
        });

        FB.XFBML.parse(document.getElementById('fb-root'));
        FB.CustomerChat.show(false);
      };


      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      script.nonce = 'qMNI3Pve';
      script.type = 'text/javascript';

      script.src = `https://connect.facebook.net/${this.language}/sdk/xfbml.customerchat.js`;
      script.id = 'facebook-jssdk';

      const fbRoot = document.getElementById('fb-root');
      if (fbRoot != null) {
        fbRoot.appendChild(script);
      }
    }
  }
}
