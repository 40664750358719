<ng-container *transloco="let t; read: 'faq'">
  <div appScrollTo
    class="page-info-panel floating-header mat-elevation-z5"
  >
    <h2 *ngIf="t('title') != ' '">{{ t("title") }}</h2>
    <div *ngIf="t('info') != ' '" class="mat-elevation-z2 accent-contrast">
      <app-formatted-text [text]="t('info')"></app-formatted-text>
    </div>
  </div>
</ng-container>
<div class="container">
  <ng-container *ngIf="faqs != null">
    <section *ngFor="let s of faqs; trackBy: trackBy" class="mat-elevation-z2 widget-background">
      <h2>{{ getQA(s).q }}</h2>
      <app-formatted-text [text]="getQA(s).a"></app-formatted-text>

      <div *ngFor="let sub of s.subs; trackBy: trackBy" class="subFaq">
        <h3>{{ getQA(sub).q }}</h3>
        <app-formatted-text [text]="getQA(sub).a"></app-formatted-text>
      </div>

      <img
        *ngIf="s.faq.getImage()"
        loading="lazy"
        [appFirestoreImage]="s.faq.getImage()"
        [attr.aria-label]="getQA(s).q"
      />
    </section>
  </ng-container>
</div>
