<ng-container *transloco="let t; read: 'header'">
  <header>
    <mat-toolbar color="primary">
      <img
        routerLink="/"
        class="logo"
        src="/assets/images/Emili_logo.jpg"
        width="960"
        height="960"
        [alt]="t('logo_alt')"
      />
      <div class="titles">
        <h1>{{ t("title_main") }}</h1>
        <div class="main-sub-title">{{ t("title_main_sub") }}</div>
      </div>

      <nav class="navigation">
        <button
          mat-icon-button
          class="small-show"
          [matMenuTriggerFor]="menu"
          [attr.aria-label]="t('nav_menu')"
        >
          <i class="material-icons md-36 md-dark">menu</i>
        </button>

        <mat-menu #menu="matMenu">
          <a
            mat-menu-item
            routerLink="/"
            routerLinkActive="active-menu-item"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ t("nav.home") }}
          </a>
          <a
            mat-menu-item
            routerLink="/tellimine"
            routerLinkActive="active-menu-item"
          >
            {{ t("nav.booking") }}
          </a>
          <a
            mat-menu-item
            routerLink="/kkk"
            routerLinkActive="active-menu-item"
          >
            {{ t("nav.faq") }}
          </a>
          <a
            mat-menu-item
            routerLink="/video"
            routerLinkActive="active-menu-item"
          >
            {{ t("nav.videos") }}
          </a>
          <a
            mat-menu-item
            routerLink="/pildid"
            routerLinkActive="active-menu-item"
          >
            {{ t("nav.gallery") }}
          </a>
          <mat-divider></mat-divider>
          <a
            mat-menu-item
            routerLink="/admin"
            routerLinkActive="active-menu-item"
          >
          {{ t("nav.admin") }}
        </a>
          <!-- <a
            mat-menu-item
            routerLink="/videos"
            routerLinkActive="active-menu-item"
          >
            Videod
          </a> -->
        </mat-menu>
      </nav>

      <nav
        mat-tab-nav-bar
        mat-align-tabs="center"
        class="navigation small-hide"
      >
        <a mat-tab-link [active]="router.isActive('/', true)" routerLink="/">
          {{ t("nav.home") }}
        </a>
        <a
          mat-tab-link
          [active]="router.isActive('/tellimine', false)"
          routerLink="/tellimine"
        >
          {{ t("nav.booking") }}
        </a>
        <a
          mat-tab-link
          [active]="router.isActive('/kkk', false)"
          routerLink="/kkk"
        >
          {{ t("nav.faq") }}
        </a>
        <a
          mat-tab-link
          [active]="router.isActive('/video', false)"
          routerLink="/video"
        >
          {{ t("nav.videos") }}
        </a>
        <a
          mat-tab-link
          [active]="router.isActive('/pildid', false)"
          routerLink="/pildid"
        >
          {{ t("nav.gallery") }}
        </a>
        <a
          mat-tab-link
          class="navigationIcon"
          [active]="router.isActive('/admin', false)"
          routerLink="/admin"
          [attr.aria-label]="t('nav.admin')"
        >
          <i class="material-icons md-24">admin_panel_settings</i>
        </a>
        <!-- <a
          mat-tab-link
          [active]="router.isActive('/videos', false)"
          routerLink="/videos"
        >
          Videod
        </a> -->
      </nav>
    </mat-toolbar>
  </header>
</ng-container>
