import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'src/app/core/admin.service';
import Booking, { BookingStatus } from 'src/app/data/Booking';
import { SubscriberComponent } from '../../shared/subscriber.component';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent extends SubscriberComponent implements OnInit {

  bookingsTableColumns = ['date', 'name', 'email', 'location', 'status', 'id', 'errors'];
  bookingStatuses = Object.values(BookingStatus);

  /*
  // TODO "when objects are added, removed, or moved on the data array,
  you can trigger an update to the table's rendered rows by calling its renderRows() method."
  bookingsTable: MatTable<Booking> | null = null;
  @ViewChild(MatTable)
  set setBookingsTable(table: MatTable<Booking>) {
    if (table != null) {
      // Table set
      // console.log(table);
    }
  }
  */

  bookings: Booking[] | null;
  selectedBooking: Booking | null;

  unhandledRequests: string[] | null;

  constructor(
    private adminService: AdminService,
    private activeRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {

    combineLatest([this.adminService.getBookings(), this.activeRoute.paramMap])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([bookings, paramMap]) => {
        this.bookings = bookings;
        const bookingId = paramMap.get('id');
        if (this.bookings != null && bookingId != null && bookingId !== 'all') {
          const booking = this.bookings.find(b => b.id === bookingId);
          this.selectedBooking = booking;
        } else {
          this.selectedBooking = null;
        }
      });

    this.adminService.getUnhandledRequests()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(ids => this.unhandledRequests = ids);
  }

}
