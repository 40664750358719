import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';

export const dateFormat = { day: 'numeric', month: 'short', year: 'numeric' };
export const dateFormats: MatDateFormats = {
    parse: {
        dateInput: dateFormat
    },
    display: {
        dateInput: dateFormat,
        monthYearLabel: { month: 'short', year: 'numeric' },
        dateA11yLabel: { day: 'numeric', month: 'long', year: 'numeric' },
        monthYearA11yLabel: { month: 'long', year: 'numeric' },
    }
};

@Injectable()
export class LocaleParsingDateAdapter extends NativeDateAdapter {
    constructor(@Inject(LOCALE_ID) private matDateLocale: string, platform: Platform) {
        super(matDateLocale, platform);
    }
    getFirstDayOfWeek(): number {
        // This as app specific :/
        return 1;
    }
    parse(value: any): Date | null {
        // The only way to parse locale specific date string is using moment.js
        return super.parse(value);
    }
}

export function getCurrentDate(): Date {
    const date = new Date(0);
    const currentDate = new Date();
    date.setFullYear(currentDate.getFullYear());
    date.setMonth(currentDate.getMonth());
    date.setUTCDate(currentDate.getUTCDate());
    // Same as date picker
    date.setHours(0);
    return date;
}

export function convertDateToLocal(dateString: string): Date {
    if (dateString == null) {
        return null;
    }
    const date = new Date(dateString);
    const minutes = date.getMinutes() - date.getTimezoneOffset();
    date.setMinutes(minutes);
    return date;
}

export function convertDateToUTC(dateString: string): Date {
    if (dateString == null) {
        return null;
    }
    const date = new Date(dateString);
    const minutes = date.getMinutes() + date.getTimezoneOffset();
    date.setMinutes(minutes);
    return date;
}
